import { ColumnApi, GridApi, IRowNode } from 'ag-grid-community';
import multiDownload from 'multi-download';
import * as R from 'ramda';
import { Dispatch } from 'redux';
import { capitalizedFirstLetter, sleep } from 'utils/utils';

import axios from 'axios';
import axiosInstance from 'config/AxiosConfig';
import {
  ActionType,
  API_ENDPOINTS,
  ModelName,
  QueueModalType,
} from 'utils/enum';
import { processFeedback } from './feedback/utils/processFeedback';
import * as actionTypes from './GlobalActionsTypes';
import {
  AgGridApi,
  ModalAction,
  ModalEventData,
  QueueModalInfoType,
} from './GlobalReducer';

type SetStackModalTypePayload = {
  modelName: string;
  currentAction?: ModalAction;
  isFromBlockBFD?: boolean;
  isModalOpenByQuery?: boolean;
};

type SetStackModalTypeArgs = SetStackModalTypePayload & {
  modelIds: number[];
  afterEditModelItem?: boolean;
};

export const openDrawer = () => ({
  type: actionTypes.OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: actionTypes.CLOSE_DRAWER,
});

export const setEventModalData = (payload: ModalEventData) => ({
  type: actionTypes.SET_MODAL_EVENT_DATA,
  payload,
});
export const clearEventModalData = () => ({
  type: actionTypes.CLEAR_MODAL_EVENT_DATA,
});

const fetchMultipleViewModel =
  (
    modelName: string,
    modelIds: number[],
    currentAction: ModalAction,
    isFromBlockBFD?: boolean,
    afterEditModelItem?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    const { data: modalData } = await axiosInstance.post(
      `/${API_ENDPOINTS.models.getMultipleViewModel}/${capitalizedFirstLetter(
        modelName,
      )}`,
      { db_ids: modelIds },
    );

    if (modalData?.error) {
      return dispatch({
        type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
        payload: modalData?.error,
      });
    }

    return dispatch({
      type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
      payload: {
        currentAction,
        modalInfo: {
          modelName,
          modalData,
          action: ModalAction.View,
          isMultiple: true,
          currentModelIds: modelIds,
          isFromBlockBFD,
        },
        afterEditModelItem,
      },
    });
  };
const fetchViewModel =
  (
    modelName: string,
    modelIds: number[],
    currentAction: ModalAction,
    isFromBlockBFD?: boolean,
    afterEditModelItem?: boolean,
  ) =>
  async (dispatch: Dispatch) => {
    const { data: modalData } = await axiosInstance.get(
      `/${API_ENDPOINTS.models.getViewModel}/${capitalizedFirstLetter(
        modelName,
      )}/${modelIds.join(',')}`,
    );

    if (modalData?.error) {
      return dispatch({
        type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
        payload: modalData?.error,
      });
    }

    return dispatch({
      type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
      payload: {
        currentAction,
        modalInfo: {
          modelName,
          modalData,
          action: ModalAction.View,
          currentModelIds: modelIds,
          isFromBlockBFD,
        },
        afterEditModelItem,
      },
    });
  };

const fetchMultipleEditModel =
  (modelName: string, modelIds: number[], currentAction: ModalAction) =>
  async (dispatch: Dispatch) => {
    const { data: choices } = await axiosInstance.get(
      `/${API_ENDPOINTS.models.getChoices}/${capitalizedFirstLetter(
        modelName,
      )}`,
    );

    const { data: modalData } = await axiosInstance.post(
      `/${API_ENDPOINTS.models.getMultipleViewModel}/${capitalizedFirstLetter(
        modelName,
      )}`,
      { db_ids: modelIds },
    );

    if (modalData?.error || choices?.error) {
      return dispatch({
        type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
        payload: modalData?.error ?? choices?.error,
      });
    }

    return dispatch({
      type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
      payload: {
        currentAction,

        modalInfo: {
          modelName,
          modalData: { ...choices, ...modalData },
          action: ModalAction.Edit,
          isMultiple: true,
          currentModelIds: modelIds,
        },
      },
    });
  };
const fetchEditModel =
  (modelName: string, modelIds: number[]) => async (dispatch: Dispatch) => {
    const { data: choices } = await axiosInstance.get(
      `/${API_ENDPOINTS.models.getEditChoices}/${capitalizedFirstLetter(
        modelName,
      )}/${modelIds[0]}`,
    );

    if (choices?.error) {
      return dispatch({
        type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
        payload: choices?.error,
      });
    }

    return dispatch({
      type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
      payload: {
        currentAction: ModalAction.Edit,
        modalInfo: {
          modelName,
          modalData: choices,
          action: ModalAction.Edit,
          currentModelIds: modelIds,
        },
      },
    });
  };

export const fetchProgramData = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.FETCH_FEEDBACK_DATA_START,
  });
  try {
    const { data: programData } = await axiosInstance.get(
      `/${API_ENDPOINTS.feedback}/${id}`,
    );

    if (programData?.errors?.length > 0) {
      return dispatch({
        type: actionTypes.FETCH_FEEDBACK_DATA_ERROR,
        payload: programData?.errors,
      });
    }

    return dispatch({
      type: actionTypes.FETCH_FEEDBACK_DATA_SUCCESS,
      payload: processFeedback(programData),
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errorMessage = (err.response?.data as { error: string }).error;

      dispatch({
        type: actionTypes.FETCH_FEEDBACK_DATA_ERROR,
        payload: errorMessage,
      });
      return err.response?.data;
    } else {
      dispatch({
        type: actionTypes.FETCH_FEEDBACK_DATA_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  }
};
export const clearProgramData = () => ({
  type: actionTypes.CLEAR_FEEDBACK_DATA,
});

export const setStackModalData =
  ({
    modelName,
    modelIds,
    currentAction,
    isModalOpenByQuery,
    isFromBlockBFD,
    afterEditModelItem,
  }: SetStackModalTypeArgs) =>
  async (dispatch: Dispatch) => {
    let payload: SetStackModalTypePayload = {
      modelName,
      currentAction,
    };

    if (isModalOpenByQuery) {
      payload = { ...payload, isModalOpenByQuery };
    }

    dispatch({
      type: actionTypes.FETCH_STACK_MODAL_DATA_START,
      payload,
    });

    if (currentAction === ModalAction.View) {
      if (afterEditModelItem) {
        await sleep(2000);
      }

      try {
        if (modelIds.length > 1) {
          await fetchMultipleViewModel(
            modelName,
            modelIds,
            currentAction,
            isFromBlockBFD,
            afterEditModelItem,
          )(dispatch);
        } else {
          await fetchViewModel(
            modelName,
            modelIds,
            currentAction,
            isFromBlockBFD,
            afterEditModelItem,
          )(dispatch);
        }
      } catch (err) {
        console.log(err.message);
        if (axios.isAxiosError(err)) {
          const errorMessage =
            (err.response?.data as { error: string }).error ??
            (err.response?.data as { Message: string }).Message;

          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: errorMessage,
          });
          return err.response?.data;
        } else {
          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: err.message,
          });
        }
      }
    }

    if (currentAction === ModalAction.Edit) {
      try {
        if (modelIds.length > 1) {
          await fetchMultipleEditModel(
            modelName,
            modelIds,
            currentAction,
          )(dispatch);
        } else {
          await fetchEditModel(modelName, modelIds)(dispatch);
        }
      } catch (err) {
        console.log(err.message);
        if (axios.isAxiosError(err)) {
          const errorMessage = (err.response?.data as { error: string }).error;

          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: errorMessage,
          });
          return err.response?.data;
        } else {
          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: err.message,
          });
        }
      }
    }

    if (currentAction === ModalAction.Create) {
      if (
        modelName === ModelName.Program ||
        modelName === ModelName.Material ||
        modelName === ModelName.Process ||
        modelName === ModelName.Measurement
      ) {
        return dispatch({
          type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
          payload: {
            currentAction,
            modalInfo: {
              modelName,
              modalData: {},
              action: ModalAction.Create,
              currentModelIds: [],
            },
          },
        });
      }
      try {
        const { data: choices } = await axiosInstance.get(
          `/${API_ENDPOINTS.models.getChoices}/${capitalizedFirstLetter(
            modelName,
          )}`,
        );

        if (choices?.error) {
          return dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: choices?.error,
          });
        }

        return dispatch({
          type: actionTypes.FETCH_STACK_MODAL_DATA_SUCCESS,
          payload: {
            currentAction,
            modalInfo: {
              modelName,
              modalData: choices,
              action: ModalAction.Create,
              currentModelIds: [],
            },
          },
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = (err.response?.data as { error: string }).error;

          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: errorMessage,
          });
          return err.response?.data;
        } else {
          dispatch({
            type: actionTypes.FETCH_STACK_MODAL_DATA_ERROR,
            payload: err.message,
          });
          console.log(err.message);
        }
      }
    }
  };
export const clearStackModalData = (payload?: { deep: number }) => ({
  type: actionTypes.CLEAR_STACK_MODAL_DATA,
  payload: payload ?? { deep: 1 },
});
export const clearAllStackModalData = () => ({
  type: actionTypes.CLEAR_ALL_STACK_MODAL_DATA,
});
export const setGridApiToStackModalData = (payload: {
  gridApi: GridApi | null;
  columnApi: ColumnApi | null;
}) => ({
  type: actionTypes.SET_GRID_API_TO_STACK_MODAL_DATA,
  payload,
});

export const fetchModelDefinitions = () => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.MODEL_DEFINITIONS_START });
  try {
    const { data: modelDefinitions } = await axiosInstance.get(
      `/${API_ENDPOINTS.models.getModelDefinitions}`,
    );

    if (modelDefinitions?.error) {
      return dispatch({
        type: actionTypes.MODEL_DEFINITIONS_ERROR,
        payload: { message: modelDefinitions.message, error: true },
      });
    }

    return dispatch({
      type: actionTypes.MODEL_DEFINITIONS_SUCCESS,
      payload: modelDefinitions.data,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errorMessage = (err.response?.data as { error: string }).error;

      dispatch({
        type: actionTypes.MODEL_DEFINITIONS_ERROR,
        payload: errorMessage,
      });
      return err.response?.data;
    } else {
      dispatch({
        type: actionTypes.MODEL_DEFINITIONS_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  }
};

export const createModelItem =
  (modelName: string, data: Record<string, any>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CREATE_MODEL_ITEM_START });
    try {
      const { data: modelItem } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.createModelItem}/${capitalizedFirstLetter(
          modelName,
        )}`,
        data,
      );
      if (modelItem.error) {
        dispatch({
          type: actionTypes.CREATE_MODEL_ITEM_ERROR,
          payload: modelItem.error,
        });
        return modelItem;
      }

      dispatch({
        type: actionTypes.CREATE_MODEL_ITEM_SUCCESS,
        payload: R.propOr('', 'db_ids', modelItem),
      });

      return modelItem;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.CREATE_MODEL_ITEM_ERROR,
          payload: errorMessage,
        });
        return err.response?.data;
      } else {
        dispatch({
          type: actionTypes.CREATE_MODEL_ITEM_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const editModelItem =
  (modelName: string, data: Record<string, any>, db_id: number) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.EDIT_MODEL_ITEM_START });
    try {
      const { data: result } = await axiosInstance.put(
        `/${API_ENDPOINTS.models.editModelItem}/${capitalizedFirstLetter(
          modelName,
        )}/${db_id}`,
        data,
      );

      if (result.error) {
        dispatch({
          type: actionTypes.EDIT_MODEL_ITEM_ERROR,
          payload: result.error,
        });

        return result;
      }

      dispatch({
        type: actionTypes.EDIT_MODEL_ITEM_SUCCESS,
        payload: result,
      });

      return result;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.EDIT_MODEL_ITEM_ERROR,
          payload: errorMessage,
        });
        return err.response?.data;
      } else {
        dispatch({
          type: actionTypes.EDIT_MODEL_ITEM_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const editModelMultipleItem =
  (modelName: string, data: Record<string, any>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.EDIT_MODEL_MULTIPLE_ITEM_START });
    try {
      const { data: modelItem } = await axiosInstance.put(
        `/${
          API_ENDPOINTS.models.editMultipleModelItem
        }/${capitalizedFirstLetter(modelName)}`,
        data,
      );

      if (modelItem.error) {
        dispatch({
          type: actionTypes.EDIT_MODEL_MULTIPLE_ITEM_ERROR,
          payload: modelItem.error,
        });

        return modelItem;
      }

      dispatch({
        type: actionTypes.EDIT_MODEL_MULTIPLE_ITEM_SUCCESS,
        payload: R.propOr('', 'db_ids', modelItem),
      });

      return modelItem;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.EDIT_MODEL_MULTIPLE_ITEM_ERROR,
          payload: errorMessage,
        });
        return err.response?.data;
      } else {
        dispatch({
          type: actionTypes.EDIT_MODEL_MULTIPLE_ITEM_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const updateQueueModalState =
  (payload: QueueModalInfoType) => (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.UPDATE_QUEUE_MODAL_STATE, payload });

export const clearQueueModalState = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_QUEUE_MODAL_STATE });

export const clearCreatedStateModelItem = () => async (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_STATE_MODEL_ITEM });

export const cancelCheck =
  (modelName: string, data: Record<string, any>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CANCEL_CHECK_START });
    try {
      const { data: cancel_check } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.deleteCheck}/${capitalizedFirstLetter(
          modelName,
        )}`,
        data,
      );

      if (cancel_check.error) {
        return dispatch({
          type: actionTypes.CANCEL_CHECK_ERROR,
          payload: cancel_check.error,
        });
      }

      return dispatch({
        type: actionTypes.CANCEL_CHECK_SUCCESS,
        payload: R.propOr('', 'result', cancel_check),
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.CANCEL_CHECK_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.CANCEL_CHECK_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const cancelMultiple =
  ({
    modelName,
    data,
    queueModalType,
  }: {
    modelName: ModelName;
    data: Record<string, any>;
    queueModalType: QueueModalType;
  }) =>
  async (dispatch: Dispatch) => {
    const showQueueModalPayload = {
      showModal: true,
      modalType: queueModalType,
      modelName,
      isSendingProcessingRequest: false,
    };

    dispatch({
      type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
      payload: {
        ...showQueueModalPayload,
        isSendingProcessingRequest: true,
      },
    });

    try {
      const { data: cancel_multiple } = await axiosInstance.delete(
        `/${API_ENDPOINTS.models.cancelMultiple}/${capitalizedFirstLetter(
          modelName,
        )}`,
        {
          data,
        },
      );

      return dispatch({
        type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
        payload: {
          processingError: cancel_multiple.error ? cancel_multiple.error : '',
          ...showQueueModalPayload,
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;
        dispatch({
          type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
          payload: {
            processingError: errorMessage,
            ...showQueueModalPayload,
          },
        });
      } else {
        dispatch({
          type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
          payload: {
            processingError: err.message,
            ...showQueueModalPayload,
          },
        });
        console.log(err.message);
      }
    }
  };
export const clearCancelState = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_CANCEL_STATE });

export const reactivateMultiple =
  (
    modelName: string,
    data: Record<string, any>,
    queueModalType: QueueModalType,
  ) =>
  async (dispatch: Dispatch) => {
    const showQueueModalPayload = {
      showModal: true,
      modalType: queueModalType,
      modelName,
      isSendingProcessingRequest: false,
    };

    dispatch({
      type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
      payload: {
        ...showQueueModalPayload,
        isSendingProcessingRequest: true,
      },
    });

    dispatch({ type: actionTypes.REACTIVATE_MULTIPLE_START });
    try {
      const { data: reactivate_multiple } = await axiosInstance.put(
        `/${API_ENDPOINTS.models.reactivateMultiple}/${capitalizedFirstLetter(
          modelName,
        )}`,
        data,
      );

      return dispatch({
        type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
        payload: {
          processingError: reactivate_multiple.error
            ? reactivate_multiple.error
            : '',
          ...showQueueModalPayload,
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;
        dispatch({
          type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
          payload: {
            processingError: errorMessage,
            ...showQueueModalPayload,
          },
        });
      } else {
        dispatch({
          type: actionTypes.UPDATE_QUEUE_MODAL_STATE,
          payload: {
            processingError: err.message,
            ...showQueueModalPayload,
          },
        });
        console.log(err.message);
      }
    }
  };
export const clearReactivateState = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_REACTIVATE_STATE });

export const fetchMpmFileIds =
  (modelName: string, data: Record<string, any>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CLEAR_DOWNLOAD_ORIGINAL_FILE_STATE });
    dispatch({ type: actionTypes.FETCH_MPM_FILE_IDS_START });
    try {
      const { data: fileIds } = await axiosInstance.post(
        `/${API_ENDPOINTS.upload.downloadMPM}/${capitalizedFirstLetter(
          modelName,
        )}`,
        data,
      );

      if (fileIds.error) {
        return dispatch({
          type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
          payload: fileIds.error,
        });
      }

      return dispatch({
        type: actionTypes.FETCH_MPM_FILE_IDS_SUCCESS,
        payload: R.propOr('', 'result', fileIds),
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

export const downloadFiles =
  (data: Record<string, any>) => async (dispatch: Dispatch) => {
    try {
      const {
        data: { result: urls, error },
      } = await axiosInstance.post(`/${API_ENDPOINTS.upload.download}`, data);

      if (!error) {
        multiDownload(urls);
        return dispatch({
          type: actionTypes.DOWNLOAD_ORIGINAL_FILES_FINISHED,
        });
      } else {
        return dispatch({
          type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
          payload: error,
        });
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

export const getFileUrls =
  (data: Record<string, any>) => async (dispatch: Dispatch) => {
    try {
      const {
        data: { result: urls, error },
      } = await axiosInstance.post(`/${API_ENDPOINTS.upload.download}`, data);

      if (error) {
        return dispatch({
          type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
          payload: error,
        });
      }
      return urls;
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_MPM_FILE_IDS_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

export const clearDownloadOriginalFileState =
  () => async (dispatch: Dispatch) =>
    dispatch({
      type: actionTypes.CLEAR_DOWNLOAD_ORIGINAL_FILE_STATE,
    });

interface fetchTableProps {
  params?: Record<string, any>;
  modelApi: string;
  modelName: string;
  shouldUpdateState?: boolean;
}

export const fetchTable =
  ({
    params = {},
    modelApi,
    modelName,
    shouldUpdateState = true,
  }: fetchTableProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: `FETCH_${ActionType[modelName]}_START` });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${modelApi}`,
        params,
      );

      if (data.error) {
        return dispatch({
          type: `FETCH_${ActionType[modelName]}_ERROR`,
          payload: data.error,
        });
      }

      data.lastRow = data?.rows?.length > 0 ? data?.lastRow + 1 : 0;

      data.shouldUpdateState = shouldUpdateState;

      return dispatch({
        type: `FETCH_${ActionType[modelName]}_SUCCESS`,
        payload: data,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: `FETCH_${ActionType[modelName]}_ERROR`,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: `FETCH_${ActionType[modelName]}_ERROR`,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const setTableFilter = (
  modelName: string,
  payload: Record<string, any>,
) => ({
  type: `SET_${ActionType[modelName]}_TABLE_FILTER`,
  payload,
});
export const setTableSort = (
  modelName: string,
  payload: Record<string, any>,
) => ({
  type: `SET_${ActionType[modelName]}_TABLE_SORT`,
  payload,
});
export const setShowCancel = (modelName: string) => ({
  type: `SET_${ActionType[modelName]}_SHOW_CANCEL`,
});

export const setSelectedNodes = (modelName: string, payload: IRowNode[]) => ({
  type: `SET_${ActionType[modelName]}_SELECTED_NODES`,
  payload,
});

export const fetchContextMenuData =
  (modelName: ModelName, filterOptions: Record<string, any>) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.FETCH_CONTEXT_MENU_DATA_START });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${modelName}`,
        {
          filterModel: filterOptions,
        },
      );

      if (data.error) {
        return dispatch({
          type: `FETCH_${ActionType[modelName]}_ERROR`,
          payload: data.error,
        });
      }

      return dispatch({
        type: actionTypes.FETCH_CONTEXT_MENU_DATA_SUCCESS,
        payload: data['rows'],
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.FETCH_CONTEXT_MENU_DATA_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_CONTEXT_MENU_DATA_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const fetchOrganizationsForCurrentUser =
  (showOrganizationUrl: boolean) => async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USER_ORGANIZATIONS_START,
    });
    try {
      const { data } = await axiosInstance.get(
        `/${API_ENDPOINTS.auth.organizations}`,
      );

      if (data?.error) {
        return dispatch({
          type: actionTypes.FETCH_USER_ORGANIZATIONS_ERROR,
          payload: data.error,
        });
      }

      return dispatch({
        type: actionTypes.FETCH_USER_ORGANIZATIONS_SUCCESS,
        payload: {
          data: data,
          selectedOrganization: data[0],
          activeOrganization: showOrganizationUrl ? data[0] : null,
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log(err);
        const errorMessage =
          (err.response?.data as { error: string })?.error ?? err.message;

        dispatch({
          type: actionTypes.FETCH_USER_ORGANIZATIONS_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_USER_ORGANIZATIONS_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const clearContextMenuData = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_CONTEXT_MENU_DATA });

export const setAgGridApi = (agGridApi: AgGridApi) => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.SET_AG_GRID_API, payload: agGridApi });

export const fetchConfiguration = () => async (dispatch: Dispatch) => {
  dispatch({ type: actionTypes.FETCH_CONFIGURATION_START });
  try {
    const { data } = await axiosInstance.get(`/${API_ENDPOINTS.configuration}`);

    return dispatch({
      type: actionTypes.FETCH_CONFIGURATION_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const errorMessage =
        (err.response?.data as { error: string })?.error ?? err.message;

      dispatch({
        type: actionTypes.FETCH_CONFIGURATION_ERROR,
        payload: errorMessage,
      });
      return err.response?.data;
    } else {
      dispatch({
        type: actionTypes.FETCH_CONFIGURATION_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  }
};
