import { push } from '@lagunovsky/redux-react-router';
import { Dispatch } from 'redux';

import rootAxios from 'axios';
import axios from 'config/AxiosConfig';
import {
  getAccessToken,
  getExpirationDateToken,
  getRefreshToken,
} from 'helpers/localeStorage';
import { NavigateFunction } from 'react-router-dom';
import { API_ENDPOINTS, ModelName } from 'utils/enum';
import * as actionTypes from './AuthActionsTypes';
import { SignUpWithEmailFormInputs } from './forms/signUp/SignUpWithEmail';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSanitizedUserPermissionList, isTokenExpired } from './utils';

export const fetchUserInfo =
  (navigate?: (path: string) => void, organizationNamespace?: string) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.FETCH_USER_INFO_START });

    try {
      const { data } = await axios.get(`/${API_ENDPOINTS.auth.user}`);
      const teamQuery = data?.info?.teams?.length
        ? `?team:in=${data?.info?.teams.join(',')}`
        : '';
      dispatch({
        type: actionTypes.FETCH_USER_INFO_SUCCESS,
        payload: {
          ...data?.info,
          permissions: getSanitizedUserPermissionList(
            data?.claims?.permissions,
          ),
        },
      });

      if (navigate && teamQuery) {
        const pathWithOrg = organizationNamespace
          ? `/${organizationNamespace}/${ModelName.Experiment}${teamQuery}`
          : `/${ModelName.Experiment}${teamQuery}`;
        navigate(pathWithOrg);
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_USER_INFO_ERROR,
        payload: err.message,
      });
    }
  };

interface loginProps {
  email: string;
  password: string;
}
export const login =
  (
    navigate: NavigateFunction,
    userInfo: loginProps,
    organizationNamespace?: string,
  ) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.LOGIN_START });
    dispatch({ type: actionTypes.FETCH_USER_INFO_START });

    let isLoginSuccessful = false;

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.login, {
        ...userInfo,
      });
      if (data.error) {
        return dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: data.message,
        });
      }

      isLoginSuccessful = true;

      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (rootAxios.isAxiosError(err)) {
        const errorMessage =
          err.response?.status === 401
            ? 'Login failed, please check your email address and password'
            : err.response?.status === 403
              ? 'Authenticated, but user not found or is inactive.  Please contact your admin'
              : err.message;

        dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: errorMessage,
        });
      } else {
        return dispatch(logout(null, true));
      }
    }

    if (isLoginSuccessful) {
      try {
        const { data: userData } = await axios.get(
          `/${API_ENDPOINTS.auth.user}`,
        );
        dispatch({
          type: actionTypes.FETCH_USER_INFO_SUCCESS,
          payload: {
            ...userData?.info,
            permissions: getSanitizedUserPermissionList(
              userData?.claims?.permissions,
            ),
          },
        });
        const teamQuery = userData?.info.teams?.length
          ? `?team:in=${userData.info.teams.join(',')}`
          : '';

        const pathWithOrg = organizationNamespace
          ? `/${organizationNamespace}/experiment${teamQuery}`
          : `/experiment${teamQuery}`;

        navigate(pathWithOrg);
      } catch (err) {
        dispatch({
          type: actionTypes.FETCH_USER_INFO_ERROR,
          payload: err.message,
        });
      }
    }
  };

interface loginRefreshProps {
  refresh_token: string;
}
export const loginRefresh =
  ({ refresh_token }: loginRefreshProps) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.LOGIN_REFRESH_START });

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.loginRefresh, {
        refresh_token,
      });

      return data.error
        ? dispatch(logout())
        : dispatch({
            type: actionTypes.LOGIN_REFRESH_SUCCESS,
            payload: data,
          });
    } catch (err) {
      dispatch({
        type: actionTypes.LOGIN_REFRESH_ERROR,
        payload: err.message,
      });
      return dispatch(logout());
    }
  };

export const logout =
  (access_token: string | null = null, shouldKeepError?: boolean) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.LOGOUT_START });

    try {
      if (access_token) {
        await axios.post(`/${API_ENDPOINTS.auth.logout}`, { access_token });
      }
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
        payload: {
          shouldKeepError,
        },
      });

      dispatch(push('/auth/login'));
    } catch (err) {
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
        payload: {
          shouldKeepError,
        },
      });
      dispatch(push('/auth/login'));
    }
  };

export const checkAuth = () => async (dispatch: any) => {
  try {
    const expirationTokenDate = getExpirationDateToken();
    const refresh_token = getRefreshToken();
    const access_token = getAccessToken();

    if (
      !expirationTokenDate ||
      (isTokenExpired(expirationTokenDate) && !refresh_token)
    ) {
      return dispatch(logout(access_token));
    }

    if (isTokenExpired(expirationTokenDate) && refresh_token) {
      return dispatch(loginRefresh({ refresh_token }));
    }
  } catch (err) {
    return dispatch(logout());
  }
};

interface pwChangeProps {
  curPassword: string;
  newPassword: string;
}
export const pwChange =
  ({ curPassword, newPassword }: pwChangeProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWCHANGE_START });

    try {
      const access_token = getAccessToken();
      const { data } = await axios.post(API_ENDPOINTS.auth.pwchange, {
        prev_password: curPassword,
        curr_password: newPassword,
        access_token,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWCHANGE_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWCHANGE_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/experiment'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWCHANGE_ERROR,
        payload: err.message,
      });
    }
  };

interface pwForgotProps {
  email: string;
}
export const pwForgot =
  ({ email }: pwForgotProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWFORGOT_START });

    try {
      const { data } = await axios.post(`/${API_ENDPOINTS.auth.pwforgot}`, {
        email,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWFORGOT_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWFORGOT_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/auth/password-reset-sent'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWFORGOT_ERROR,
        payload: err.message,
      });
    }
  };

interface pwForgotConfirmProps {
  email: string | null;
  code: string | null;
  password: string;
}
export const pwForgotConfirm =
  ({ email, code, password }: pwForgotConfirmProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWFORGOT_CONFIRM_START });

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.pwforgotconfirm, {
        email,
        code,
        password,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWFORGOT_CONFIRM_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWFORGOT_CONFIRM_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/auth/password-update-success'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWFORGOT_CONFIRM_ERROR,
        payload: err.message,
      });
    }
  };

export const authResetFormState = () => ({
  type: actionTypes.AUTH_RESET_FORM,
});

export const checkUserStatus =
  (email: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_START });
      const { data: userStatus } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.userStatus}`,
        { email },
      );
      if (userStatus.error) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
          payload: userStatus.message ?? 'Error checking user status',
        });
      }
      if (
        userStatus.exists &&
        userStatus.confirmed &&
        userStatus.has_organization
      ) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
          payload: { userStatus: 'login', userEmail: email },
        });
      } else if (
        userStatus.exists &&
        userStatus.confirmed &&
        !userStatus.has_organization
      ) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
          payload: { userStatus: 'organization', userEmail: email },
        });
      } else {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
          payload: { userStatus: 'confirm', userEmail: email },
        });
      }
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
        payload: "Couldn't check user status",
      });
    }
  };

export const sendConfirmationEmail =
  (userInfo: SignUpWithEmailFormInputs) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_START });
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.sendConfirmationEmail}`,
        {
          email: userInfo.email,
          given_name: userInfo.givenName,
          family_name: userInfo.familyName,
        },
      );
      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
          payload: data.message ?? 'Error sending confirmation email',
        });
      }
      dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
        payload: {
          userStatus: `Confirmation email sent to ${userInfo.email}`,
          userEmail: userInfo.email,
        },
      });
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
        payload: "Couldn't send confirmation email",
      });
    }
  };

export const createUser =
  (userInfo: { password: string; confirmationCode: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.createUser}`,
        {
          password: userInfo.password,
          uuid_code: userInfo.confirmationCode,
        },
      );

      if (data?.error) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
          payload: data.message ?? 'Error creating user',
        });
      }

      const userEmail = data?.email;

      dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
        payload: { userStatus: 'organization', userEmail },
      });
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
        payload: "Couldn't create a user",
      });
    }
  };

export const validateNamespace = async (namespace: string) => {
  try {
    const { data } = await axios.post(
      `/${API_ENDPOINTS.auth.signup.validateNamespace}`,
      {
        namespace,
      },
    );

    return data;
  } catch (err) {
    return {
      error: true,
      message: err.message,
    };
  }
};

export const createOrganization =
  (organizationName: string, namespace: string, email: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_START });
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.createOrganization}`,
        {
          ns_display: organizationName,
          namespace,
          email,
          make_default: true,
        },
      );

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
          payload: data.message ?? 'Error creating organization',
        });
      }

      dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_SUCCESS,
        payload: { userStatus: 'finish', userEmail: email },
      });
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_SIGNUP_CHECK_USER_STATUS_ERROR,
        payload: "Couldn't create organization",
      });
    }
  };

export const checkInvitationStatus =
  (invite_id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CHECK_INVITATION_START });

    const defaultErrorMessage =
      'Something went wrong during invitation status check. Try again later';
    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.invitationStatus}`,
        {
          invite_id,
        },
      );

      if (data.error) {
        return dispatch({
          type: actionTypes.CHECK_INVITATION_ERROR,
          payload: data.message ?? defaultErrorMessage,
        });
      }

      dispatch({
        type: actionTypes.CHECK_INVITATION_SUCCESS,
        payload: data.confirmed,
      });
    } catch (err) {
      if (rootAxios.isAxiosError(err)) {
        const errorMessage =
          (err.response?.data as { Message: string }).Message || err.message;

        dispatch({
          type: actionTypes.CHECK_INVITATION_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.CHECK_INVITATION_ERROR,
          payload: err.message ?? defaultErrorMessage,
        });
        console.log(err.message);
      }
    }
  };

export const createInvitedUser =
  (inviteInfo: {
    invite_id: string;
    given_name: string;
    family_name: string;
    password: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CREATE_INVITED_USER_START });

    const defaultErrorMessage =
      'Something went wrong during user creation, try again later.';
    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.createInvitedUser}`,

        inviteInfo,
      );

      if (data.error) {
        return dispatch({
          type: actionTypes.CREATE_INVITED_USER_ERROR,
          payload: data.message ?? defaultErrorMessage,
        });
      }

      dispatch({
        type: actionTypes.CREATE_INVITED_USER_SUCCESS,
        payload: data.message ?? 'User successfully created',
      });
    } catch (err) {
      if (rootAxios.isAxiosError(err)) {
        const errorMessage =
          (err.response?.data as { Message: string }).Message || err.message;

        dispatch({
          type: actionTypes.CREATE_INVITED_USER_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.CREATE_INVITED_USER_ERROR,
          payload: err.message ?? defaultErrorMessage,
        });
        console.log(err.message);
      }
    }
  };

export const acceptInvitation =
  (invite_id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.ACCEPT_INVITE_START });

    const defaultErrorMessage =
      'Something went wrong during accepting invite, try again later.';
    try {
      const { data } = await axios.post(
        `/${API_ENDPOINTS.auth.signup.acceptInvitation}`,
        {
          invite_id,
        },
      );

      if (data.error) {
        return dispatch({
          type: actionTypes.ACCEPT_INVITE_ERROR,
          payload: data.message ?? defaultErrorMessage,
        });
      }

      dispatch({
        type: actionTypes.ACCEPT_INVITE_SUCCESS,
        payload: data.message ?? 'Invite accepted',
      });
    } catch (err) {
      if (rootAxios.isAxiosError(err)) {
        const errorMessage =
          (err.response?.data as { Message: string }).Message || err.message;

        dispatch({
          type: actionTypes.ACCEPT_INVITE_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.ACCEPT_INVITE_ERROR,
          payload: err.message ?? defaultErrorMessage,
        });
        console.log(err.message);
      }
    }
  };
