import * as R from 'ramda';

import { ModelName } from '../../utils/enum';
import { STATE_KEY, VisualizeDataType } from './VisualizeReducer';

export const getVisualizeData = (
  state: Record<string, any>,
  modelName: ModelName | 'matrix',
): VisualizeDataType => R.pathOr('', [STATE_KEY, modelName], state);

export const getVisualizeChartsInfo = (
  state: Record<string, any>,
  modelName: ModelName,
): VisualizeDataType['charts'] => {
  return R.pathOr('', [STATE_KEY, modelName, 'charts'], state);
};

export const getVisualizeChartRawDataInfo = (
  state: Record<string, any>,
  modelName: ModelName,
): VisualizeDataType['chartRawDataInfo'] => {
  return R.pathOr('', [STATE_KEY, modelName, 'chartRawDataInfo'], state);
};
