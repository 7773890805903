import styled from 'styled-components';

import { CircularProgress } from '@mui/material/';

const Loader = ({
  size = 40,
  fullWidth = true,
}: {
  size?: number;
  fullWidth?: boolean;
}) => (
  <Wrapper $fullWidth={fullWidth}>
    <CircularProgress size={size} />
  </Wrapper>
);

const Wrapper = styled.span<{ $fullWidth: boolean }>`
  display: flex;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '')};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default Loader;
