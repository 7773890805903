import UndoIcon from '@mui/icons-material/Undo';
import { IconButton, Typography } from '@mui/material';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getMeasurements } from 'modules/measurements/MeasurementsSelectors';
import {
  fetchChartRawData,
  refetchScatterChart,
  updateChartSettings,
} from 'modules/visualize/VisualizeActions';
import {
  ScatterSettingsType,
  VisualizePanelsTypeEnum,
} from 'modules/visualize/VisualizeReducer';
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import { ModelName } from 'utils/enum';
import { getVisualizeChartRawDataInfo } from '../../../VisualizeSelectors';
import { ScatterChartAxesBasesType } from '../models/scatterChartModels';
import { ScatterChartResponse } from '../models/scatterChartResponseModel';
import { getScatterColorGroups } from '../scatterChartUtils';
import AxesSettings from './AxesSettings';
import ChartSettings from './ChartSettings';
import LayoutSettings from './LayoutSettings';
import PointsSettings from './PointsSettings';

interface ScatterSettingsProps {
  settingsInfo: ScatterSettingsType;
  initialSettings: ScatterSettingsType;
  panelType: VisualizePanelsTypeEnum;
  chartId: string;
  chartData: ScatterChartResponse;
  filterModel: Record<string, any>;
  isLoading: boolean;
  axesBases?: ScatterChartAxesBasesType;
  children?: React.ReactNode;
  modelName: ModelName;
  showCancel: boolean;
}

export enum ScatterSettingsAccordionEnum {
  Chart = 'chart',
  Layout = 'layout',
  Axes = 'axes',
  Points = 'points',
}

export enum CenterLineOptions {
  Mean = 'mean',
  Median = 'median',
  None = 'none',
}

export enum SizingOptions {
  Fit = 'Fit to screen',
  Fixed = 'Fixed',
}

const xAxisChoices = [
  {
    name: 'datetime',
    label: 'datetime',
  },
  {
    name: 'value',
    label: 'value',
  },
];

const yAxisChoices = [
  {
    name: 'value',
    label: 'value',
  },
];

const groupByChoices = [
  {
    name: 'pro_type',
    label: 'pro_type',
  },
];

const ScatterSettings: React.FC<ScatterSettingsProps> = ({
  settingsInfo,
  panelType,
  chartId,
  initialSettings,
  chartData,
  axesBases,
  isLoading,
  filterModel,
  showCancel,
  modelName,
}) => {
  const [expandedAccordion, setExpandedAccordion] =
    useState<ScatterSettingsAccordionEnum | null>(
      ScatterSettingsAccordionEnum.Chart,
    );

  const [shouldUpdateSettings, setShouldUpdateSettings] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { sortModel } = useAppSelector(getMeasurements);
  const {
    isCompleted: isChartRawDataCompleted,
    loading: isChartRawDataLoading,
  } = useAppSelector((state) => getVisualizeChartRawDataInfo(state, modelName));

  const nullChart = chartData?.['null'];

  const colorByChoices = useMemo(
    () => getScatterColorGroups(nullChart),
    [nullChart],
  );

  const {
    register,
    control,
    setValue,
    formState: { errors, isValid, isValidating },
    watch,
    reset,
    trigger,
  } = useForm<ScatterSettingsType>({
    defaultValues: initialSettings,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const formValues = useWatch({
    control,
  });

  const showResetButton = useMemo(
    () => !R.equals(initialSettings, formValues),
    [initialSettings, formValues],
  );

  const isAxesOptionWasChanged = useMemo(
    () =>
      !R.equals(settingsInfo.xAxis, formValues.xAxis) ||
      !R.equals(settingsInfo.yAxis, formValues.yAxis),
    [
      settingsInfo.xAxis,
      settingsInfo.yAxis,
      formValues.xAxis,
      formValues.yAxis,
    ],
  );

  const isDomainAxesOptionWasChanged = useMemo(
    () =>
      !R.equals(settingsInfo.xMaxAxis, formValues.xMaxAxis) ||
      !R.equals(settingsInfo.xMinAxis, formValues.xMinAxis) ||
      !R.equals(settingsInfo.yMaxAxis, formValues.yMaxAxis) ||
      !R.equals(settingsInfo.yMinAxis, formValues.yMinAxis),
    [
      settingsInfo.xMaxAxis,
      settingsInfo.xMinAxis,
      settingsInfo.yMaxAxis,
      settingsInfo.yMinAxis,
      formValues.xMaxAxis,
      formValues.xMinAxis,
      formValues.yMaxAxis,
      formValues.yMinAxis,
    ],
  );

  const isChartAccordionExpanded =
    expandedAccordion === ScatterSettingsAccordionEnum.Chart;
  const isPointsAccordionExpanded =
    expandedAccordion === ScatterSettingsAccordionEnum.Points;
  const isAxesAccordionExpanded =
    expandedAccordion === ScatterSettingsAccordionEnum.Axes;
  const isLayoutAccordionExpanded =
    expandedAccordion === ScatterSettingsAccordionEnum.Layout;

  const isInitialLabelByOptionChanged =
    formValues.labelBy.length > 1 ||
    (formValues.labelBy.length === 1 && formValues.labelBy[0].name !== 'db_id');

  useEffect(() => {
    if ((isValid && !isValidating) || shouldUpdateSettings) {
      const timeoutId = setTimeout(() => {
        dispatch(
          updateChartSettings({
            chartId,
            panelType,
            updatedSettings: formValues,
            modelName,
            shouldResetDataSet:
              shouldUpdateSettings || isDomainAxesOptionWasChanged,
          }),
        );

        if (isAxesOptionWasChanged) {
          dispatch(
            refetchScatterChart({
              chartId: chartId,
              showCancel,
              filter: {
                filterModel,
                sortModel,
                chartSettings: {
                  x_column_name: formValues.xAxis.name,
                  y_column_name: formValues.yAxis.name,
                  center_mean: formValues.centerLine === CenterLineOptions.Mean,
                  color_by: null,
                  group_by: null,
                  label_by: [],
                },
              },
              modelName,
              panelType: panelType,
              shouldUpdateSettings,
            }),
          );
        }

        setShouldUpdateSettings(false);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [
    dispatch,
    isDomainAxesOptionWasChanged,
    reset,
    chartId,
    isValidating,
    panelType,
    isAxesOptionWasChanged,
    formValues,
    filterModel,
    shouldUpdateSettings,
    sortModel,
    isValid,
    modelName,
    showCancel,
    setShouldUpdateSettings,
  ]);

  useEffect(() => {
    if (
      !isLoading &&
      !isChartRawDataCompleted &&
      !isChartRawDataLoading &&
      isInitialLabelByOptionChanged
    ) {
      dispatch(
        fetchChartRawData({
          filter: {
            chartSettings: {},
            filterModel,
            sortModel,
          },
          modelName,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isChartRawDataCompleted,
    isChartRawDataLoading,
    isInitialLabelByOptionChanged,
    isLoading,
  ]);

  useEffect(() => {
    if (!isLoading) {
      reset(settingsInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, reset]);

  return (
    <div>
      <SectionTitle>
        Settings
        <ActionsButtonsWrapper>
          {isLoading && <Loader size={20} />}
          {showResetButton && (
            <IconButton
              disableRipple
              onClick={() => {
                reset(initialSettings);
                setShouldUpdateSettings(true);
              }}
            >
              <UndoIcon />
            </IconButton>
          )}
        </ActionsButtonsWrapper>
      </SectionTitle>

      <ChartSettings
        isExpanded={isChartAccordionExpanded}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? ScatterSettingsAccordionEnum.Chart : null,
          );
        }}
        control={control}
        xAxisChoices={axesBases?.xAxis ?? xAxisChoices}
        yAxisChoices={axesBases?.yAxis ?? yAxisChoices}
        setValue={setValue}
        formValues={formValues}
      />

      <PointsSettings
        isExpanded={isPointsAccordionExpanded}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? ScatterSettingsAccordionEnum.Points : null,
          );
        }}
        control={control}
        groupByChoices={groupByChoices}
        colorByChoices={colorByChoices}
        labelByChoices={initialSettings.labelByChoices}
        setValue={setValue}
        formValues={formValues}
      />

      <AxesSettings
        isExpanded={isAxesAccordionExpanded}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? ScatterSettingsAccordionEnum.Axes : null,
          );
        }}
        setValue={setValue}
        register={register}
        watch={watch}
        control={control}
        errors={errors}
      />

      <LayoutSettings
        isExpanded={isLayoutAccordionExpanded}
        onChange={(e, expanded) => {
          setExpandedAccordion(
            expanded ? ScatterSettingsAccordionEnum.Layout : null,
          );
        }}
        setValue={setValue}
        register={register}
        errors={errors}
        initialSettings={initialSettings}
        formValues={formValues}
        trigger={trigger}
      />
    </div>
  );
};

const SectionTitle = styled(Typography)`
  font-size: 16px;
  color: ${colors.primary};
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    padding: 0;
    margin-left: 5px;
  }
`;

const ActionsButtonsWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export default ScatterSettings;
